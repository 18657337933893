// import logo from './logo.svg';
import React from 'react'
// import { Link } from "react-router-dom";
import './App.css';
import logo from '../src/img/logo.png';
import twitter from '../src/img/twitter.svg';
import snapshot from '../src/img/snapshot.png';
import TokenVideo from '../src/img/token-icon.mp4'


function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
      <div className='header-center-divs'>
      <div className='header'>
        <div className='container'>
          <div className='logo'>
            <a href='#'>
              <img src={logo} alt='logo'/>
              </a>
          </div>
          <div className='right-logos'>
            <div>
              <a href=''>
              <img src={twitter} alt=''/>
              </a>
            </div>
            <div>
            <a href='https://www.docs.morra.foundation/'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="M10.266 12.565c1.076.622 1.613.933 2.204.933.59 0 1.129-.31 2.206-.93l6.863-3.952a1 1 0 0 0 0-1.734L14.673 2.93c-1.075-.62-1.613-.93-2.203-.929-.59 0-1.128.31-2.203.93L4.365 6.335l-.086.05A6.64 6.64 0 0 0 1 12.066v.2a6.64 6.64 0 0 0 3.358 5.726l3.697 2.139C10.21 21.377 11.287 22 12.47 22c1.183 0 2.26-.622 4.416-1.866l3.903-2.254c1.079-.623 1.618-.935 1.915-1.449.296-.513.296-1.137.296-2.385v-2.41a.954.954 0 0 0-1.427-.828l-8.003 4.609c-.537.309-.806.463-1.1.463-.295 0-.564-.154-1.1-.463l-5.417-3.114c-.272-.156-.407-.234-.516-.248a.552.552 0 0 0-.582.34c-.042.101-.04.258-.04.571.002.23.003.346.024.452.048.238.173.453.355.613.082.071.181.129.381.244l5.791 3.348c.539.312.808.467 1.104.467.295 0 .565-.155 1.103-.466l7.099-4.099c.184-.106.276-.16.345-.12.069.04.069.147.069.36v1.093c0 .312 0 .468-.074.596-.074.129-.21.207-.479.362l-5.855 3.381c-1.078.623-1.617.934-2.208.934-.592 0-1.13-.313-2.208-.936l-5.477-3.17-.035-.02a3.77 3.77 0 0 1-1.863-3.234v-1.043c0-.736.39-1.416 1.026-1.785a1.81 1.81 0 0 1 1.816-.001l4.537 2.623Z"></path></svg>
            </a>
            </div>
            <div>
            <a href='https://snapshot.org/#/citizenfi.eth'>
           <img src={snapshot} width={'35px'} alt='snapshot'/>
            </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container-custom section_100vh morra-token">
              <div className="image-container">
                {/* <img src={ecoToken} alt="Morra Token" /> */}
                <video video loop autoplay='' muted className='morra-token-videoo'>
                    <source src={TokenVideo} type="video/mp4"/>
                </video>
              </div>
              <div className="text-container">
                <h2 className='h_84 section-heading'>Play, Earn, Govern <br/> – The DAO Way</h2>
                <div className='spacer_16'></div>
                <p className='section-paragraph p_14'>
                $MORRA is a native cryptocurrency created to fuel the next generation of gaming DAO culture, where token holders play a crucial role in game development and publishing.
                </p>
                <div className='spacer_16'></div>
                <div className='spacer_16'></div>

                <div className="buttons">
                  <a href="https://www.mexc.com/exchange/MORRA_USDT" className="button mexc">MEXC</a>
                  <a href="https://app.uniswap.org/explore/tokens/ethereum/0xd9adfb67381d392c6e9671f64cdd9014bfcd74f2" className="button uniswap">Uniswap</a>
                </div>
              </div>
      </div>
      <div className='footer'>
        <div className='container'>
          <div className='copyright'>
            <p>@ 2024 Morra Foundation. All rights reserved.</p>
          </div>
          <div className='terms-condition'>
            <p>Terms & Conditions</p>
            <p>Privacy Policy</p>

          </div>
        </div>
      </div>
      </div>
      
    </div>
  );
}

export default App;
